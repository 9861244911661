import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthGuard } from '../auth/auth.guard';

interface ItemsResponseObj { }
interface ItemsResponse { }

@Injectable({
providedIn: 'root'
})

export class CommonService  {

  loggedagentname;
  cppframevalue;
  userstaus;

  constructor(private httpClient: HttpClient) { }

  setagentname(data) {
    this.cppframevalue = data;
   // console.log(".....", this.cppframevalue);
  }

  getccpframe(){
    return this.cppframevalue;
  }

  setstaus(status){
   this.updatestus(status);
  }

  updatestus(data){
    this.userstaus = data;
   // this.statsComponent.userstatusfunc(this.userstaus);
  }

  loggeduserdata(userObj) {
    // return this.httpClient.post<ItemsResponse>('https://mdoncology.stellarit-ai.com/dashboardv/agentmetrics/agent-records', userObj);
    this.httpClient.post(environment.apiUrl+'agentmetrics/agent-records', userObj).subscribe((res) => {
     // console.log("...........", res);
      });
  }

  loggedUserUpdateDuration(userObj) {
    this.httpClient.post(environment.apiUrl+'agentmetrics/agent-duration', userObj).subscribe((res) => {
    //  console.log("...........", res);
      });
  }
  
}

