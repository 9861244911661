import { Component, OnInit } from '@angular/core';

export interface PeriodicElement {
  time:any;
  dot:any;
  name: string;
  position: any;
  weight: number;
  symbol: string;
  agent: string;
  contactId:string;
  customerendpoint:any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {time:'Today', dot:'./assets/img/dot1.png', position: 'at 11:00 AM', name: 'Hydrogen', weight: 1.0079, symbol: 'H', agent: 'shiggins', contactId: '5b482806-f3d7-43c5-bc56-871775c721bf', customerendpoint: '+18105774123'},
  {time:'', dot:'./assets/img/dot2.png', position: 'at 2:00 PM', name: 'Helium', weight: 4.0026, symbol: 'He', agent: '	ejouvent', contactId: 'f143ed1b-9bd8-47ab-b69c-da191ac13466', customerendpoint: '	+447948387896	'},
  {time:'', dot:'./assets/img/dot1.png', position: 'at 9:14 AM', name: 'Lithium', weight: 6.941, symbol: 'Li', agent: 'emass', contactId: 'c5533645-4032-45a3-a724-ead99ae6434b', customerendpoint: '+13607907145'},
  {time:'Yesterday', dot:'./assets/img/dot2.png', position: 'at 01:24 PM', name: 'Beryllium', weight: 9.0122, symbol: 'pfuentes', agent: 'pfuentes', contactId: '06a67906-28e1-419c-b1cf-81b4c19b154e', customerendpoint: '	+17176718919'},
  {time:'', dot:'./assets/img/dot1.png', position: 'at 05:17 PM', name: 'Boron', weight: 10.811, symbol: 'B', agent: 'bstrong', contactId: '5b482806-f3d7-43c5-bc56-871775c721bf', customerendpoint: '	+14127604108'},
  {time:'', dot:'./assets/img/dot2.png', position: 'at 03:28 PM', name: 'Carbon', weight: 12.0107, symbol: 'C', agent: 'mcabezas', contactId: '06a67906-28e1-419c-b1cf-81b4c19b154e', customerendpoint: '+18105774123'},
  {time:'', dot:'./assets/img/dot1.png', position: 'at 04:00 PM', name: 'Nitrogen', weight: 14.0067, symbol: 'N', agent: 'ejouvent', contactId: 'ddf9df0c-026a-4ab7-8308-9fbbb23fd177', customerendpoint: '	+17176718919'},
  {time:'', dot:'./assets/img/dot2.png', position: 'at 10:30 AM', name: 'Oxygen', weight: 15.9994, symbol: 'O', agent: 'shiggins', contactId: '5b482806-f3d7-43c5-bc56-871775c721bf', customerendpoint: '+18105774123'},
  {time:'', dot:'./assets/img/dot1.png', position: 'at 10:30 AM', name: 'Fluorine', weight: 18.9984, symbol: 'F', agent: 'bstrong', contactId: 'c5533645-4032-45a3-a724-ead99ae6434b', customerendpoint: '+13607907145'},
  {time:'', dot:'./assets/img/dot2.png', position: 'at 10:30 AM', name: 'Neon', weight: 20.1797, symbol: 'Ne', agent: 'pfuentes', contactId: 'f143ed1b-9bd8-47ab-b69c-da191ac13466', customerendpoint: '	+447948387896	'},
];

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.css']
})

export class ComplianceComponent implements OnInit {

  displayedColumns: string[] = ['time', 'dot', 'position', 'name', 'weight', 'symbol', 'agent', 'contactId', 'customerendpoint'];
  dataSource = ELEMENT_DATA;

  constructor() { }

  ngOnInit() {
  }

}
