import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SupervisorSidebarComponent } from './supervisorsidebar.component';

@NgModule({
    imports: [ RouterModule, CommonModule ],
    declarations: [ SupervisorSidebarComponent ],
    exports: [ SupervisorSidebarComponent ]
})

export class SupervisorSidebarModule {}
