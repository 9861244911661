import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AuthGuard } from './auth/auth.guard';
import * as $ from 'jquery';

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  private _router: Subscription;
  subscription: Subscription;

  constructor( private router: Router ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        AuthGuard.browserRefresh = !router.navigated;
      }
  });
  localStorage.setItem("search_option", "false");
   }

    ngOnInit() {
      // $('#containerDiv').append(AuthGuard.appendvalue);

      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
    }
}
