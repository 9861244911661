import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../auth/auth.guard';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  private _router: Subscription;
  constructor(private router: Router, ) { }

  ngOnInit() {
    //console.log(" AuthGuard.agentname..............", AuthGuard.agentname);
    if (AuthGuard.agentname) {
      if (AuthGuard.agentname == "Admin") {
     //  this.router.navigate(['/supervisor-advancedanalytics/trendanalytics']);
       this.router.navigate(['/supervisor']);
      } else if (AuthGuard.agentname == "CallCenterManager") {
        this.router.navigate(['/supervisor']);
      } else if (AuthGuard.agentname == "Supervisor") {
        this.router.navigate(['/supervisor']);
      }else if (AuthGuard.agentname == "Agent") {
        this.router.navigate(['/dashboard']);
      } else if (AuthGuard.agentname == "IAM@d-906719d9b9") {
        this.router.navigate(['/supervisor']);
      }
    }
  }
}
