import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AuthGuard } from '../auth/auth.guard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username;
 
  constructor(private router:Router) { }

  loginapp(){
    document.getElementById('landing').style.display = "none";
    document.getElementById('loading').style.display = "block";
    this.router.navigate(['/profile']);
  }


//    subscribeToContactEvents(contact) {
//     window.myCPP.contact = contact;
    
// }

  

 
  ngOnInit() {

    this.username = JSON.parse(localStorage.getItem('loginuser'));
   // console.log(".......in login component.....", this.username);

    if(this.username){
    this.router.navigate(['/profile']);
    }  else {
      document.getElementById('landing').style.display = "block";
      document.getElementById('loading').style.display = "none";
    } 
  
  }

}
