import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../service/common.service';
declare const $: any;
declare var connect: any;
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  myCPP: any;
  static appendvalue: any
  static agentname: any;
  static username: any;
  static duration: any;
  static userstatus: any;
  static agentStates: any;
  static agentnew: any;
  timeString;
  static loginusername: any;
  static browserRefresh = false;
  static currentStaus;
  static callimage;
  static queuelist;
  // static loginflag = false;   

  constructor(private router: Router, private loggeduserService: CommonService, private httpClient: HttpClient) { }

  handleContactEnded() {
    // console.log("inside handleContactEnded................................");  
    document.getElementById("incomingcall").style.display = "none";
    document.getElementById("nocall").style.display = "block";
  }

  login() {
    this.myCPP = this.myCPP || {};
    var ccpUrl = "https://yogaalliance.awsapps.com/connect/ccp#/";
    AuthGuard.appendvalue = document.getElementById('containerDiv');
    connect.core.initCCP(AuthGuard.appendvalue, {
      ccpUrl: ccpUrl,
      loginPopup: true,
      softphone: {
        allowFramedSoftphone: true
      }
    });

    connect.contact((contact) => {
      // console.log("inside contact");

      //   console.log("1 ", contact.getStatus().type);
      if (contact.getStatus().type == "connecting") {
        document.getElementById("incomingcall").style.display = "block";
        document.getElementById("nocall").style.display = "none";
        $("#myModal").modal();
      }
      contact.onEnded(this.handleContactEnded);
    });


    connect.agent(this.subscribeToAgentEvents);

    connect.agent((agent) => {
     // console.log(".......inside connect....", connect.agent);
     // console.log(".......inside connect login username....", agent.getConfiguration().username);
      AuthGuard.agentStates = agent.getAgentStates();
      AuthGuard.agentnew = agent;
      AuthGuard.currentStaus = agent.getStatus().name;
      // AuthGuard.loginflag = true;

      AuthGuard.queuelist = agent.getRoutingProfile().queues;

      var userObj = {
        userName: agent.getConfiguration().username,
        status: agent.getStatus().name,
        duration: agent.getStatusDuration(),
        routingProfile: agent.getRoutingProfile().name,
        queueName: agent.getRoutingProfile().defaultOutboundQueue.name,
        hierarchyName: " ",
        state: "1"
      }

      // var userObj = '{"userName":"' + agent.getConfiguration().username + '", "status":"' + agent.getStatus().name + '", "duration":"' + agent.getStatusDuration() + '", "routingProfile":"' + agent.getRoutingProfile().name + '", "queueName":"' + agent.getRoutingProfile().defaultOutboundQueue.name + '", "hierarchyName":"' + " " + '", "state":"' + "1" + '"}';

      // this.httpClient.post('https://mdoncology.stellarit-ai.com/dashboardv/agentmetrics/agent-records', userObj).subscribe((res) => {
      // console.log("...........", res);
      // });


      // console.log("...........", userObj);
      // console.log("...........", AuthGuard.appendvalue.innerHTML);



      localStorage.setItem('currentUserccpframe', JSON.stringify(AuthGuard.appendvalue.innerHTML));
      localStorage.setItem('currentUser', JSON.stringify(userObj));

      this.loggeduserService.loggeduserdata(userObj);
      //AuthGuard.callimage = "./assets/img/call1.png"
      agent.onRefresh(() => {
        localStorage.setItem('username', JSON.stringify(agent.getName()));
        $("#loginusername").text(agent.getName());
        $("#userstaus").text(agent.getStatus().name);
        // $("#listofStatus").val(agent.getStatus().name);

        if (AuthGuard.currentStaus != agent.getStatus().name) {
          //   console.log("inside differnet status");

          var userObj_change = {
            userName: agent.getConfiguration().username,
            status: agent.getStatus().name,
            duration: agent.getStatusDuration(),
            routingProfile: agent.getRoutingProfile().name,
            queueName: agent.getRoutingProfile().defaultOutboundQueue.name,
            hierarchyName: " ",
            state: "1"
          }
          this.loggeduserService.loggeduserdata(userObj_change);
          AuthGuard.currentStaus = agent.getStatus().name;
        }

        var userObj_duration = {
          userName: agent.getConfiguration().username,
          status: agent.getStatus().name,
          duration: agent.getStatusDuration(),
          state: "1"
        }
        this.loggeduserService.loggedUserUpdateDuration(userObj_duration);

        setInterval(function () {
          if (agent.getStatus().type == "offline") {
            $("#userduration").text("");
          } else {
            var time = agent.getStatusDuration();
            var ms = time % 1000;
            time = (time - ms) / 1000;
            var secs = time % 60;
            time = (time - secs) / 60;
            var mins = time % 60;
            var hrs = (time - mins) / 60;
            //this.timeString = hrs + ':' + mins + ':' + secs + ' - ';
            this.timeString = hrs + 'h :' + mins + 'm :' + secs + 's - ';
            // console.log("timestring...........", this.timeString);
            $("#userduration").text(this.timeString);
            // var userObj_duration = {
            //   userName: agent.getConfiguration().username,
            //   status: agent.getStatus().name,
            //   duration: agent.getStatusDuration(),
            //   state: "1"
            // }
            // this.httpClient.post('https://mdoncology.stellarit-ai.com/dashboardv/agentmetrics/agent-duration', userObj_duration).subscribe((res) => {
            //   console.log("........... inside duration update ", res);
            //   });
          }
        }, 10)

      })

    })

    connect.core.getEventBus().subscribe(connect.EventType.TERMINATED, function () {
      localStorage.removeItem('loginuser');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUserccpframe');
      localStorage.removeItem('username');
      localStorage.removeItem('iframer_loader_session');
      document.getElementById('yogadashboard').style.display = "none";
      window.location.href = 'https://stellarit-ai.com/';
    });

// 
  }


  subscribeToAgentEvents(agent) {

    if (agent.getName()) {
      AuthGuard.loginusername = agent.getConfiguration().username;
      var queue = agent.getRoutingProfile().queues;
     // console.log("........", queue);
      var ContactId;
      let found = queue.filter(function (item) {
        if (item.queueId.split("/").indexOf("agent") >= 0) {
          return true;
        }
        return false;
      });
      if (found.length > 0) {
        var res = found[0].queueId.split("/");
        ContactId = res[4];
      }

      //  console.log("..........", ContactId);
      if (ContactId) {
       // console.log("......contact ID....", ContactId);

        var xmlHttp = new XMLHttpRequest();
        //  xmlHttp.open("GET", "https://wk2x01u22l.execute-api.us-east-1.amazonaws.com/default/describeUser?userId=" + ContactId, false);
        xmlHttp.open("GET", "https://qwcyctbnif.execute-api.us-east-1.amazonaws.com/default/describeUser?userId=" + ContactId, false); // false for synchronous request
        xmlHttp.send(null);
        var userInfo = JSON.parse(xmlHttp.responseText);
        // console.log(".....", userInfo);
        AuthGuard.agentname = userInfo.Name;
        localStorage.setItem('loginuser', JSON.stringify(AuthGuard.agentname));
       // console.log("..lambda function...", AuthGuard.agentname);
      }
    }
  }



  available(agent) {
    //  console.log('agent ', agent.getAgentStates());
    var offlineState = agent.getAgentStates().filter(function (state) {
      return state.type === connect.AgentStateType.ROUTABLE;
    })[0];
    agent.setState(offlineState, {
      success: function () {
        //   console.log('agent sucess');
      },
      failure: function () {
        //   console.log('agent failure');
      }
    });

  }

  goAvailable() {
    connect.agent(this.available);
  }

  goOffline() {
    connect.agent(this.offline);
  }

  offline(agent) {
    var offlineState = agent.getAgentStates().filter(function (state) {
      return state.type === connect.AgentStateType.OFFLINE;
    })[0];
    agent.setState(offlineState, {
      success: function () {
        // console.log('agent sucess');
      },
      failure: function () {
        //  console.log('agent failure');
      }
    });
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

    this.login();

    return new Promise((resolve) => {
      if (typeof AuthGuard.agentname === 'undefined') {
       // console.log("..............", AuthGuard.agentname);
      // AuthGuard.agentname = "Admin"
      //     AuthGuard.duration = "77271990"
        var myflag = setInterval(function () {
          if (typeof AuthGuard.agentname !== 'undefined') {
            clearInterval(myflag);
            resolve(true);
          }
        }, 10000);
      }
    })
    return false;
  }

}