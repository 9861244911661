import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css']
})
export class AssistantComponent implements OnInit {

  msg: any = '';
  submitted: any = false;
  questionlist: any = [];
  questionAskedlist: any = [];
  constructor() { }

  ngOnInit() {
    this.questionlist = [
      { 
        question: `Provide the call volume on a daily basis for the month of June 2023`, 
        answer: 
        { 
          title: `Certainly, here's the daily call volume data for the entire month of June 2023 :`,
           result: `Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| Call Volume
           <br>
           -------------------|-------------------- 
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>
           2023-06-01 | 102
           <br>`
        }
      },
      { 
        question: `How many negative calls were handled yesterday?`, 
        answer: 
        { 
          title: `The number of calls with a negative sentiment that were managed yesterday is:`,
           result: `47
           <br>
           <br>
           Busy Days of the week:
           <br>
          Monday: 1,190 calls (10.9% of total calls)
          <br>
          Tuesday: 890 calls
          <br>
          Wednesday: 740 calls
          <br>
          Thursday: 925 calls
          <br>
          Friday: 1,042 calls
          <br>
          Saturday: 680 calls
          <br>
          Sunday: 446 calls`
        }
      }
    ];
  }

  send() {
    
    var questiondata= this.questionlist.find(obj => obj.question === this.msg);
    if(questiondata){
      this.submitted = true
      var length = this.questionAskedlist.push(questiondata)
    }
    this.msg = ''
  }

}
