import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatPaginator, MatInput, MatSort } from '@angular/material';
import { AdvancedanalyticsService } from 'src/app/service/advancedanalytics.service';
// import { DialogComponent } from '../../dialog/dialog.component';

import { filter } from 'rxjs-compat/operator/filter';

@Component({
  selector: 'app-seachpage',
  templateUrl: './seachpage.component.html',
  styleUrls: ['./seachpage.component.css']
})
export class SeachpageComponent implements OnInit {

  linguisticAnalyticsData: string[] = ["Sadness", "Fear", "Anger", "Confident", "Analytics", "Tentative", "Joy"];
  displayedColumns: string[] = ['connectedToDate', "audio", 'analytics', 'contactId',  'customerendpoint', 'userName',   ];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  disabled: boolean = true;
  linguisticAnalytics: any;
  confidenceValue: number = 0;
  linguisticsTone: any = {};
  totalRecords: number;
  linguisticToneData: any = [];
  previousContacId: any;
  today;
  last30days;
  startDate: Date;
  endDate: Date;
  isDate: boolean = false;
  isDatenew: boolean = false;
  minDate: Date;
  maxDate: Date;
  audioPlayerplay;
  audioPlayerpause;
  filtertext;
  pagecount;
  filterValue;
  previous_search;
  searchObj = {};

  static fromdate_lingustic;
  static enddate_lingustic;
  total_datasource = [];
  sortdata;
  sortedStatus: boolean = true;

  withoutsort: boolean = true;
  svalue;
  sstatus: boolean = true;
  svalue_contactId;
  contactid_status: boolean = true;
  svalue_username;
  svalue_status: boolean = true;
  svalue_sentimet;
  sentiment_status: boolean = true;
  svalue_connected;
  connected_status: boolean = true;

  constructor(private wcdataService: AdvancedanalyticsService, public dialog: MatDialog) { }

  

  ngOnInit() {
    this.pagecount = 10;
    this.defaultdatepicker();
    this.dataSource.sort = this.sort;
    this.linguisticAnalytics = "Anger";
    this.confidenceValue = 0.55;
    
  }

  ngAfterViewInit(){
    this.dataSource.sort = this.sort;
  }
  
  defaultdatepicker() {
    var date = new Date();
    this.today = date.toISOString().split('T')[0];
    date.setDate(date.getDate() - 88);
    this.last30days = date.toISOString().split('T')[0];
    this.wcdataService.lingusticdate(this.last30days, this.today);
    this.minDate = new Date(this.last30days);
    this.maxDate = new Date();
    this.linguisticToneScoreAnalytics();
  }


  //calling lingustic table data from API
  linguisticToneScoreAnalytics() {
  
      this.wcdataService.linguisticAnalytics("Anger", 0.55, 0, 10).subscribe(data => {
        this.linguisticsTone = data;
       // console.log("............................",this.linguisticsTone);
        this.formatting_data(this.linguisticsTone);
      });
   // }
  }

  linguisticToneScoreAnalytics_sorting(sortfield, sortstatus){
    if (this.confidenceValue > 0) {
      this.wcdataService.lingusticdate(this.last30days, this.today);
     // console.log()
      this.wcdataService.linguisticAnalytics_sorting(this.linguisticAnalytics, this.confidenceValue, 0, 10, sortfield, sortstatus, this.searchObj).subscribe(data => {
        this.linguisticsTone = data;
      //  console.log(this.linguisticsTone);
        this.formatting_data(this.linguisticsTone);
      });
    }
  }


  // Formatting the API data based on format which we need 
  formatting_data(data) {
   // console.log("................data...........", data);
    this.linguisticsTone = data;
    //console.log("qqqqqqqqqqqqqqqqqqqqq", this.linguisticsTone);
    if (this.linguisticsTone.totalNumberOfElements > 0) {
      for (var i = 0; i < this.linguisticsTone.content.length; i++) {
        var audioUrl = this.linguisticsTone.content[i];
        if (audioUrl.recordingsLocation) {
          this.linguisticsTone.content[i] = { ... this.linguisticsTone.content[i], audioUrl: "https://" + String(audioUrl.recordingsLocation).substring(0, 20) + ".s3.amazonaws.com" + String(audioUrl.recordingsLocation).substring(20) };
          this.linguisticsTone.content[i] = { ... this.linguisticsTone.content[i], audioUrlnew: "./assets/audio.mp3" };

        } else {
          this.linguisticsTone.content[i] = { ... this.linguisticsTone.content[i], audioUrl: " " };
          this.linguisticsTone.content[i] = { ... this.linguisticsTone.content[i], audioUrlnew: "./assets/audio.mp3" };
        }
      }
      this.totalRecords = this.linguisticsTone.totalNumberOfElements;
      this.dataSource.data = this.linguisticsTone.content;
      //console.log( this.dataSource.data);
      this.dataSource.sort = this.sort;

      this.total_datasource.push(this.dataSource.data);
    }

    else {
      this.dataSource.data = [];
      this.totalRecords = this.linguisticsTone.totalNumberOfElements;
    }
  }

    
  // server side Pagination function 
  pageChanged() {

    this.filterValue = (document.getElementById('filtervalue') as HTMLTextAreaElement).value;
    
    if (this.filterValue === "") {
      this.previousContacId = undefined;
      this.dataSource.data = null;
      this.callingtable_data(10);
    } else {
      this.pagination_value();
      this.previous_search = this.filterValue;
     // this.applyFilter();
    }

  }

  // pagination values for If we clicks next page
  pagination_value() {
    if(this.previous_search != this.filterValue){
      this.paginator.pageIndex = 0;
    }     
  }


  // while giving pagination calling the API data
  callingtable_data(pagesizevalue) {

    if(this.withoutsort === true){

      this.wcdataService.lingusticdate(this.last30days, this.today);
      this.wcdataService.linguisticAnalytics(this.linguisticAnalytics, this.confidenceValue, this.paginator.pageIndex, pagesizevalue).subscribe(data => {
        this.linguisticsTone = data;
      //  console.log("........pagechanged data...........", this.linguisticsTone);
        this.totalRecords = this.linguisticsTone.totalNumberOfElements;
        if (this.totalRecords === 0) {
          this.dataSource.data = [];
          this.totalRecords = this.linguisticsTone.totalNumberOfElements;
        } else {
          this.formatting_data(this.linguisticsTone);
        }
      });

    } else {
      this.callingtable_data_sorting(10);
    }

   
  }

  callingtable_data_sorting(pagesizevalue){

    this.wcdataService.lingusticdate(this.last30days, this.today);
      this.wcdataService.linguisticAnalytics_sorting(this.linguisticAnalytics, this.confidenceValue, this.paginator.pageIndex, pagesizevalue, this.svalue, this.sstatus, this.searchObj).subscribe(data => {
        this.linguisticsTone = data;
       // console.log("........pagechanged data...........", this.linguisticsTone);
        this.totalRecords = this.linguisticsTone.totalNumberOfElements;
        if (this.totalRecords === 0) {
          this.dataSource.data = [];
          this.totalRecords = this.linguisticsTone.totalNumberOfElements;
        } else {
          this.formatting_data(this.linguisticsTone);
        }
      });
  }


  //model popup content
  linguisticToneAnalytics(contactId: any) {
    this.wcdataService.linuisticToneAnalytics(contactId).subscribe(data => {
      if (data) {
        this.linguisticToneData = data;
      } else {
        this.linguisticToneData = [];
      }
      // this.dialog.open(DialogComponent, {
      //   width: '600px',
      //   height: '600px',
      //   data: { 'title': 'Tone Analytics', 'linguisticToneData': this.linguisticToneData, 'selectedword': 'Selected Tone:' + this.linguisticAnalytics + '' }
      // });
    });
  }


  //audio play button function
  onClickMe(contactId: any) {
    if (this.previousContacId) {
      let previouAaudioPlayer = <HTMLMediaElement>document.getElementById('audio-' + this.previousContacId);
      previouAaudioPlayer.pause();
      document.getElementById('play-' + this.previousContacId).style.display = 'block';
      document.getElementById('pause-' + this.previousContacId).style.display = 'none';
    }
    this.audioPlayerplay = <HTMLMediaElement>document.getElementById('audio-' + contactId);
    this.audioPlayerplay.play();
    document.getElementById('play-' + contactId).style.display = 'none';
    document.getElementById('pause-' + contactId).style.display = 'block';
    var x = this.audioPlayerplay.duration;
    this.automaticstop(x, contactId);
    this.previousContacId = contactId;

  }

//audio pause button function
  onPauseMe(contactId: any) {
    this.audioPlayerpause = <HTMLMediaElement>document.getElementById('audio-' + contactId);
    this.audioPlayerpause.pause();
    document.getElementById('play-' + contactId).style.display = 'block';
    document.getElementById('pause-' + contactId).style.display = 'none';
  }

  //calculating audio file time 
  automaticstop(seconds, contactId) {
    var milliseconds = seconds * 1000;
    var audioduration = Math.trunc(milliseconds);
    setTimeout(() => {
      this.onPauseMe(contactId);
    }, audioduration);
  }

  filterclass(filter_value){
    var x = (document.getElementById('filtervalue') as HTMLTextAreaElement).value;
       
   if(x.length > 30){

    filter_value.preventDefault();
    //console.log(x, x.length, "......contact id............");

    $('#filter_value').removeClass('col-xl-1');
    $('#filter_value').addClass('col-xl-3');
    $('#filter_value').removeClass('col-lg-3');
    $('#filter_value').addClass('col-lg-5');



    $('#filter_button').removeClass('col-xl-4');
    $('#filter_button').addClass('col-xl-3');
    $('#filter_button').removeClass('col-lg-4');
    $('#filter_button').addClass('col-lg-5');

    $('#filter_button1').removeClass('col-xl-4');
    $('#filter_button1').addClass('col-xl-3');
    $('#filter_button1').removeClass('col-lg-4');
    $('#filter_button1').addClass('col-lg-5');

    $('#row_value').removeClass('row_pad');

    $('#btn_align1').addClass('btn_alignclass');
    $('#btn_align').addClass('btn_alignclass');
       
   } else {
   
    $('#filter_value').removeClass('col-xl-3');
    $('#filter_value').addClass('col-xl-1');

    $('#filter_button').removeClass('col-xl-3');
    $('#filter_button').addClass('col-xl-4');

    $('#filter_button1').removeClass('col-xl-3');
    $('#filter_button1').addClass('col-xl-4');

    $('#row_value').addClass('row_pad');
    

   }

  }


}